/* iiif-c vars */
$blue: #0073b0;
$white: #ffffff;
$dark-blue: #003654;
$light-blue: rgba(141, 195, 223, 0.08);
$light-bluer: rgba(141, 195, 223, 0.25);
$navy: #001927;
$navy-scrim: rgba(0, 25, 39, 0.8);
$red: #a40832;
$grey: #edf0f0;
$lighter-grey: rgba(237, 240, 240, 0.2);
$black: #171717;
$greyish-brown: #4a4a4a;
$shadow: rgba(60, 64, 67, 0.2);
$caption-grey: #5f5f5f;

/* bulma overrides */

$background: $white;
$primary: $blue;
$primary-dark: $navy;
$text: $black;
$notification-background-color: $red;

$card-footer-border-top: none;

$tabs-border-bottom-width: 2px;

$box-shadow: 0 1px 2px 1px $shadow;
$box-radius: 8px;

$size-5: 1.125rem;
$size-7: 0.875rem;

$navbar-burger-color: $white;
$navbar-dropdown-arrow: $white;
$navbar-dropdown-item-active-background-color: $white;
$navbar-dropdown-background-color: $navy;

$hero-body-padding: 3.5rem 3.5rem 5rem 3.5rem;

$family-sans-serif: "PT Sans", sans-serif;
$family-serif: "Playfair Display", serif;
$family-serif-alt: "Source Serif Pro", serif;

$container-max-width: 1140px;
$content-max-width: 780px;
