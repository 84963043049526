@import "node_modules/slick-carousel-no-fonts/slick/slick.scss";
@import "node_modules/slick-carousel-no-fonts/slick/slick-theme.scss";

.slick-dots button::before {
  font-size: 2rem !important;
  padding-top: 1rem;
}

div.slick-list {
  margin: 0 40px;
}

div.slider-arrow {
  position: absolute;
  top: 50%;
  height: 36px;
  margin-top: -36px;
  line-height: 36px;
  color: $blue;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
}
div.slider-arrow:hover {
  color: $dark-blue;
}
div.slider-prev {
  left: 0;
}
div.slider-next {
  right: 0;
}
.carousel-resource {
  width: 100%;
  height: 60vh;
  background: #d8d8d8;
  background-image: linear-gradient(to bottom, #edf0f0, #d8d8d8);
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #DDD;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}
.carousel-resource img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
