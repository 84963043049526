.api-sidebar-container {
  background-color: $light-blue;
  padding-left: 3.5rem;
  padding-right: 0;
  margin-bottom: 0.75rem;
  .menu {
    padding: 3rem 3.5rem 2.5rem 0;
  }
  a {
  color: $dark-blue;
  }
}

#toc-menu {
  .menu-list {
    font-family: $family-sans-serif;
    a {
      color: $greyish-brown;
    }
  }
}

.toc-sidebar {
  font-family: $family-serif;
  font-weight: bold;

  li {
    font-size: 1.15rem;
  }
  a:hover {
    color: $blue;
  }
  a:active {
    border-left: $blue;
  }
  .toc-submenu-2{
    font-weight: normal;
  }
}

.copy-button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 4px;
  margin: 1em 1em 0 0;
  cursor: pointer;

  &::before {
    content: "Copy";
  }

  &::after {
    content: "📋";
    display: block;
  }

  // This class will be toggled via JavaScript
  &.copied {
    &::before {
      content: "Copied!";
    }

    &::after {
      content: "✔️";
    }
  }
}

.api-content {
  padding: 3.5rem;
  max-width: 100% !important;
  margin: unset !important;
  p,
  li,
  .code-header,
  .highlight {
    max-width: $content-max-width;
    font-size: 1.1rem;
  }
  table {
    border-collapse: collapse;
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    overflow-x: auto;
    td {
      border: solid 1px $black;
      border-style: inset;
      background-color: $white;
    }
    thead {
      background-color: $light-bluer;
      color: $dark-blue;
      font-size: 1rem;
      font-family: $family-serif;
      th {
        border: solid 1px $black;
        border-style: inset;
      }
    }
    tbody tr:last-child td,
    .content table tbody tr:last-child th {
      border-bottom-width: 1px;
    }
  }
  .rfc {
    color: $red;
    font-variant: small-caps;
    font-style: normal;
    font-size: 1.2em;
    line-height: 0.8333;
  }
  .warning {
    border-left: 4px solid #E05252;
    background: #fbe9e9;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 24px;
  }

  .alert, .note {
    border-left: 4px solid #E0CB52;
    background: #FCFAEE;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 24px;
  }
}

code.language-plaintext {
  padding: 0.2em 0.15em;
  font-weight: 400;
  background-color: $grey;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 0.8em;
  line-height: 2;
  color: $black;
  font-family: "Courier Prime", monospace;
}

pre.highlight {
  background-color: $black;
  color: $white;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  margin: 0 0 1em 0;

  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */

  code {
    font-family: "Courier Prime", monospace;
    font-size: 1rem;
  }
}

.code-header {
  display: flex;
  justify-content: flex-end;
  background-color: $black;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.anchorClass {
  content: url("../../assets/images/icons/link.png");
  width: 0.95em;
  display: none;
}

h2:hover .anchorClass, h3:hover .anchorClass, h4:hover .anchorClass, h5:hover .anchorClass  {
  display: inline-block;
}